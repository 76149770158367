<template>
  <div :key="componentKey" ref="wrapper">
    <div
      v-if="(field.type === 'json' && !field.singleEntry) || field.label"
      class="row align-items-center"
    >
      <div
        v-if="field.type === 'json' && !field.singleEntry"
        class="col-auto pr-0"
      >
        <button class="btn btn-icon btn-primary btn-xs ml-0" @click="addValue">
          <i class="fal fa-plus icon-sm" />
        </button>
      </div>
      <div
        v-if="field.label && field.type !== 'hidden' && !isModal"
        class="col"
      >
        <label
          v-if="field.type !== 'action'"
          :class="field.type === 'json' ? 'ma-0' : ''"
        >
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
      <div v-if="field.type === 'code' && !isModal" class="col-auto">
        <button class="btn btn-icon" @click="showCodeFullscreen">
          <i class="fal fa-expand"></i>
        </button>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div v-if="field.type === 'string' || field.type === 'int'">
          <div class="input-group">
            <div
              v-if="field.copy !== undefined && field.copy"
              v-b-tooltip.top="$t('general.copyToClipboard')"
              class="input-group-prepend cursor-pointer"
              @click="copyText(field.value)"
            >
              <span class="input-group-text">
                <i class="fas fa-copy" />
              </span>
            </div>
            <input
              v-model="value"
              :type="inputType"
              class="form-control"
              :class="validationState"
              :placeholder="field.placeholder ? field.placeholder : ''"
              :disabled="isDisabled"
              @click="showMenu()"
              @input="resetConfigDebugValue"
              @blur="checkRequiredFields"
              @keydown.esc="showConfigValues = false"
              @keydown.tab="showConfigValues = false"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                {{ getTypeCastValueType }}
              </span>
            </div>
            <div
              v-if="field.readonly !== undefined && field.readonly"
              class="input-group-append"
            >
              <span class="input-group-text">
                <i class="fas fa-lock"></i>
              </span>
            </div>
            <div
              v-if="
                field.createDataStructure !== undefined &&
                field.createDataStructure &&
                !isDisabled
              "
              v-b-tooltip.top="$t('dataStructures.create')"
              class="input-group-append"
              @click="addDataStructure"
            >
              <span class="input-group-text">
                <i class="fal fa-plus icon-lg cursor-pointer" />
              </span>
            </div>
            <div
              v-else-if="
                field.type !== 'json' && showConfigValues && !isDisabled
              "
              v-b-tooltip.top="$t('config.configCreate')"
              class="input-group-append"
              @click="addConfigValue"
            >
              <span class="input-group-text">
                <i class="fal fa-plus icon-lg cursor-pointer" />
              </span>
            </div>
            <div
              v-else-if="value && !isDisabled"
              class="input-group-append"
              @click="deleteValue"
            >
              <span class="input-group-text">
                <i class="fal fa-circle-xmark icon-lg cursor-pointer" />
              </span>
            </div>
          </div>
        </div>
        <div v-else-if="field.type === 'password'">
          <div class="input-group">
            <input
              v-model="value"
              :type="[showPassword ? 'text' : 'password']"
              class="form-control"
              :class="validationState"
              :disabled="isDisabled"
              @click="showMenu()"
              @blur="checkRequiredFields"
            />
            <div
              class="input-group-append"
              @click="showPassword = !showPassword"
            >
              <span class="input-group-text">
                <i
                  class="fal icon-lg cursor-pointer"
                  :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
                ></i>
              </span>
            </div>
          </div>
        </div>
        <div
          v-else-if="['select', 'multiSelect', 'token'].includes(field.type)"
        >
          <div class="input-group">
            <v-autocomplete
              v-model="field.value"
              class="form-control mt-0"
              :class="validationState"
              :items="selectOptions"
              item-text="label"
              item-value="value"
              :no-data-text="$t('workflowDesigner.noSelectData')"
              :menu-props="{ offsetY: true }"
              :disabled="isDisabled"
              :multiple="field.type === 'multiSelect' || field.type === 'token'"
              @input="selectChange"
            />
            <div
              v-if="field.type === 'token'"
              class="input-group-append"
              @click="showAddToken"
            >
              <span class="input-group-text cursor-pointer">
                <i class="fas fa-add" />
              </span>
            </div>
          </div>
        </div>
        <div v-else-if="field.type === 'checkbox'">
          <span class="switch">
            <label>
              <input
                v-model="value"
                type="checkbox"
                :checked="!!field.default"
                :class="validationState"
                :disabled="isDisabled"
                @blur="checkRequiredFields"
              />
              <span></span>
            </label>
          </span>
        </div>
        <div v-else-if="field.type === 'file' && Array.isArray(value)">
          <b-form-file v-model="value" :class="validationState" />
        </div>
        <div v-else-if="field.type === 'textarea'">
          <textarea
            v-model="value"
            class="form-control"
            :class="validationState"
            :disabled="isDisabled"
            rows="5"
            @click="showMenu()"
            @blur="checkRequiredFields"
            @keydown.esc="showConfigValues = false"
            @keydown.tab="showConfigValues = false"
          ></textarea>
        </div>
        <div v-else-if="field.type === 'editor'">
          <quill-editor
            ref="myTextEditor"
            class="editor"
            :options="editorOptions"
            :value="value"
            :class="validationState"
            :disabled="isDisabled"
            @change="onEditorChange"
            @click="showMenu()"
            @blur="checkRequiredFields"
            @keydown.esc="showConfigValues = false"
            @keydown.tab="showConfigValues = false"
          />
        </div>
        <div v-else-if="field.type === 'notification-warning'">
          <div class="text-danger">{{ value }}</div>
        </div>
        <div v-else-if="field.type === 'notification-muted'">
          <div class="text-muted">{{ value }}</div>
        </div>
        <div v-else-if="field.type === 'notification-info'">
          <div class="text-info">{{ value }}</div>
        </div>
        <div v-else-if="field.type === 'notification-text'">
          <div class="text-dark">{{ value }}</div>
        </div>
        <div v-else-if="field.type === 'code'">
          <prism-editor
            v-model="value"
            :class="prismEditorClass"
            :highlight="prismEditorHighlighter"
            :tab-size="tabSize"
            line-numbers
            style="max-width: 100%"
          />
        </div>
        <div v-else-if="field.type === 'action'">
          <button
            v-b-tooltip="$t(field.helpSnippet)"
            class="btn btn-primary"
            @click="fireAction(field)"
          >
            {{
              cancelAction
                ? $t(field.label) + " " + $t("general.cancel")
                : $t(field.label)
            }}
          </button>
        </div>
        <div v-else-if="field.type === 'tenantStorage'">
          <div class="input-group">
            <input
              v-model="value"
              type="text"
              readonly
              class="form-control"
              :class="validationState"
              :placeholder="field.placeholder ? field.placeholder : ''"
            />
          </div>
          <button class="btn btn-primary mt-2" @click="openFileManager()">
            {{ $t("workflowElements.fileAccess.fileManagerOpen") }}
          </button>
        </div>
        <div v-else-if="field.type === 'hidden'">
          <input v-model="value" type="hidden" />
        </div>
        <div v-else-if="field.type === 'json'">
          <div
            v-for="(f, i) in value"
            :key="i"
            class="row align-items-center mt-1"
          >
            <div v-for="(val, key) in field.fields" :key="key" class="col">
              <v-select
                v-if="val.type === 'select'"
                v-model="value[i][val.name]"
                class="form-control"
                :items="val.options"
                item-text="value"
                item-value="value"
                :disabled="isDisabled"
              />
              <input
                v-else
                v-model="value[i][val.name]"
                type="text"
                class="form-control"
                :placeholder="$t(field.label + 'Values.' + val.name)"
                :disabled="isDisabled"
                @click="showMenu(true, i, val.name)"
                @blur="checkRequiredFields"
              />
            </div>
            <div v-if="!field.singleEntry" class="col-auto pl-0">
              <button
                class="btn btn-icon btn-primary btn-xs ma-0"
                @click="removeValue(i)"
              >
                <i class="fal fa-minus icon-sm" />
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="field.type === 'time'">
          <div class="input-group">
            <input
              v-model="value"
              type="text"
              class="form-control"
              :class="validationState"
              placeholder="2d 5h 30m"
              @click="showMenu()"
            />

            <div class="input-group-append">
              <span class="input-group-text">
                <Tooltip :text="$t('workflowDesigner.timeTooltip')" />
              </span>
            </div>
          </div>
        </div>
        <div v-else-if="field.type === 'condition' && showConditions">
          <div
            v-for="(condition, i) in field.value"
            :key="i"
            class="mb-3 condition"
          >
            <div v-if="i < field.value.length - 1">
              <div class="d-flex justify-space-between align-items-center mb-1">
                <label class="d-flex align-items-center condition-label">
                  <span class="text-nowrap mr-1">{{
                    $t("workflowElements.branch.configuration.condition")
                  }}</span>
                  <div
                    class="condition-label-number text-caption rounded-circle border mr-1"
                    style="padding: 0 5px"
                  >
                    {{ i + 1 }}
                  </div>
                  :
                  <input
                    v-model="condition.label"
                    class="condition-label-input form-control form-control-sm ml-1"
                    :placeholder="
                      $t('workflowElements.branch.configuration.conditionLabel')
                    "
                  />
                  <i
                    v-if="field.value.length > 2"
                    class="condition-remove fal fa-trash text-hover-primary cursor-pointer ml-1 d-none"
                    @click="removeCondition(condition)"
                  />
                </label>
                <div>
                  <button
                    class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary"
                    @click="addBracket(condition, 'open')"
                  >
                    (
                  </button>
                  <button
                    class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary"
                    @click="addBracket(condition, 'close')"
                  >
                    )
                  </button>
                  <button
                    class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary"
                    @click="addConditionValue(condition)"
                  >
                    <i class="fal fa-plus pr-0" />
                  </button>
                </div>
              </div>
              <draggable
                v-model="condition.values"
                :group="'condition-' + i"
                @start="drag = true"
                @end="drag = false"
              >
                <div
                  v-for="(value, ii) in condition.values"
                  :key="ii"
                  :class="{ 'mb-1': ii < condition.values.length + 1 }"
                >
                  <div class="d-flex justify-space-between align-items-start">
                    <i class="fal fa-bars cursor-move mr-1 mt-3" />
                    <v-select
                      v-if="showConnectionOperator(condition, value, ii)"
                      v-model="value.connection_operator"
                      :items="connectionOperators"
                      class="form-control mr-1 connection-operator"
                      :menu-props="{ offsetY: true }"
                    />
                    <div
                      v-if="value.type === 'condition'"
                      class="w-100 d-flex justify-content-between align-items-start"
                    >
                      <FieldHelper
                        :field="{
                          type: 'string',
                          value: value.left,
                          placeholder: $t(
                            'workflowElements.branch.configuration.conditionsValues.left'
                          )
                        }"
                        :config-values="configValues"
                        :output-values="outputValues"
                        :condition-value="true"
                        :area-invalid="areaInvalid"
                        @change="setValue(value, 'left', $event)"
                      />
                      <v-select
                        v-model="value.operator"
                        :items="operators"
                        item-text="operator"
                        item-value="name"
                        class="form-control mx-1 operator w-auto"
                        :placeholder="
                          $t(
                            'workflowElements.branch.configuration.conditionsValues.operator'
                          )
                        "
                        :menu-props="{ offsetY: true }"
                        @input="operatorSelected(value, $event)"
                      />
                      <FieldHelper
                        :field="{
                          type: 'string',
                          value: value.right,
                          placeholder: $t(
                            'workflowElements.branch.configuration.conditionsValues.right'
                          )
                        }"
                        :config-values="configValues"
                        :output-values="outputValues"
                        :disabled="disableSecondField.includes(value.operator)"
                        :condition-value="true"
                        :area-invalid="areaInvalid"
                        @change="setValue(value, 'right', $event)"
                      />
                    </div>
                    <div
                      v-else
                      class="w-100 d-flex justify-content-between align-items-center"
                    >
                      <input
                        type="text"
                        :value="value.type === 'bracketOpen' ? '(' : ')'"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <button
                      v-if="condition.values.length > 1"
                      class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary ml-1"
                      @click="removeConditionValue(condition, value)"
                    >
                      <i class="fal fa-minus pr-0" />
                    </button>
                  </div>
                </div>
              </draggable>
              <div class="mt-1 ml-5 text-muted">
                {{ $t("workflowElements.branch.configuration.condition") }}:
                {{ conditionToString(condition) }}
              </div>
            </div>
            <div v-else>
              <label class="mt-3">{{
                $t("workflowElements.branch.configuration.lastCondition")
              }}</label>
            </div>
          </div>
          <button class="btn btn-primary mt-1" @click="addCondition">
            <i class="fal fa-plus" />{{
              $t("workflowElements.branch.configuration.addCondition")
            }}
          </button>
        </div>
        <div v-else-if="field.type === 'filter'">FILTER</div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
        <span v-if="configValue" class="form-text text-muted pl-1">
          {{ configValueSyntax(configValue) }}
        </span>
        <span v-else-if="debugValue" class="form-text text-muted pl-1">
          {{ debugValue.syntax }}
        </span>
        <div v-else-if="field.type === 'elementSelector'">
          <h4 v-if="elementSelectorActive">
            {{ $t("workflowElements.clickOnTheElement") }}
          </h4>
          <span v-if="elementSelectorNodeFound">
            <div class="d-flex align-items-center flex-wrap mb-8">
              <div
                class="cursor-pointer symbol symbol-50 symbol-light mr-5"
                @click="blinkElementSelectorNode"
              >
                <span class="symbol-label">
                  <img
                    :src="selectNodeElementIcon"
                    class="h-50 align-self-center"
                    alt=""
                  />
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                  @click="blinkElementSelectorNode"
                >
                  {{ elementSelectorNodeFound.attrs.data.planning.label }}
                </a>
                <span
                  class="cursor-pointer text-muted font-weight-bold"
                  @click="blinkElementSelectorNode"
                >
                  {{ elementSelectorNodeFound.attrs.label }}
                </span>
              </div>
              <button
                v-if="!elementSelectorActive"
                class="btn btn-primary mt-1 my-lg-0 my-2"
                @click="selectNodeElement"
              >
                <i class="fal fa-pencil" />
              </button>
              <b-button
                v-if="elementSelectorActive"
                variant="primary"
                @click="selectNodeElementAbort"
              >
                {{ $t("general.cancel") }}
              </b-button>
            </div>
          </span>
          <span v-else>
            <b-button
              v-if="!elementSelectorActive"
              variant="primary"
              @click="selectNodeElement"
            >
              {{ $t("workflowElements.selectElement") }}
            </b-button>
            <b-button
              v-if="elementSelectorActive"
              variant="primary"
              @click="selectNodeElementAbort"
            >
              {{ $t("general.cancel") }}
            </b-button>
          </span>
        </div>
        <span
          v-if="field.type === 'int' && isNaN(value)"
          class="form-text text-muted pl-1"
        >
          {{ value }}
        </span>
      </div>
    </div>
    <v-menu
      v-model="showConfigValues"
      content-class="bg-white"
      absolute
      offset-y
      :min-width="width"
      :max-width="width"
      :position-x="x"
      :position-y="y"
      max-height="300"
      @focus="showMenu()"
      @blur="showMenu(false)"
    >
      <v-list-item-group
        v-if="configValuesFiltered && configValuesFiltered.length > 0"
        v-model="selectedConfigValue"
        color="primary"
      >
        <v-subheader>Config Values</v-subheader>
        <v-list-item
          v-for="(item, index) in configValuesFiltered"
          :key="index"
          v-b-tooltip.left.noninteractive="
            typeof item.value === 'object'
              ? JSON.stringify(item.value)
              : item.value
          "
          @click="setConfigValue(item)"
        >
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group
        v-if="outputValuesFiltered && outputValuesFiltered.length > 0"
        color="primary"
      >
        <v-subheader>Output Values</v-subheader>
        <v-list-item
          v-for="(item, index) in outputValuesFiltered"
          :key="index"
          @click="setOutputValue(item)"
        >
          <v-list-item-title>{{ item.value }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group
        v-if="
          debugValuesAllowed &&
          debugValuesFiltered &&
          debugValuesFiltered.length > 0
        "
        color="primary"
      >
        <v-subheader>Debug Values</v-subheader>
        <v-list-item
          v-for="(item, index) in debugValuesFiltered"
          :key="index"
          v-b-tooltip.left.noninteractive="
            typeof item.value === 'object'
              ? JSON.stringify(item.value)
              : item.value
          "
          @click="setDebugValue(item)"
        >
          <v-list-item-title>{{ item.syntax }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-menu>

    <b-modal
      ref="addConfigValueModal"
      body-class="add-config-value"
      hide-footer
      hide-header
    >
      <CreateConfigValue
        :full-width="true"
        :return-to-route="false"
        :type="type"
        :value="value"
        @config-value-create-saved="configValueSaved"
        @config-value-create-cancel="$refs['addConfigValueModal'].hide()"
      />
    </b-modal>

    <b-modal
      id="codeFullScreen"
      ref="codeFullScreen"
      :title="$t(field.label)"
      size="xl"
      centered
      hide-footer
      modal-class="modal-code"
    >
      <prism-editor
        v-model="value"
        :class="prismEditorClass"
        :highlight="prismEditorHighlighter"
        :tab-size="tabSize"
        line-numbers
      />
    </b-modal>

    <b-modal
      ref="addDataStructureModal"
      body-class="add-config-value"
      :dialog-class="
        createDataStructureState === 1 ? 'create-data-structure' : ''
      "
      size="xl"
      hide-header
      hide-footer
    >
      <CreateDataStructure
        v-if="createDataStructureState === 0"
        :configuration="dataStructureConfiguration"
        :show-in-modal="true"
        :json-data="jsonData"
        @show-editor="showDataStructureEditor"
        @cancel="onDataStructureCanceled"
      />
      <Editor
        v-else-if="createDataStructureState === 1"
        :configuration="dataStructureConfiguration"
        :is-modal="true"
        @saved="onDataStructureSaved"
      />
    </b-modal>

    <b-modal
      ref="addTokenModal"
      body-class="add-config-value"
      hide-footer
      hide-header
      size="lg"
    >
      <TokenEdit
        :return-to-route="false"
        @token-saved="tokenSaved"
        @token-create-cancel="$refs['addTokenModal'].hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import Editor from "@/components/Admins/Settings/DataStructures/Editor/Editor";
import CreateDataStructure from "@/components/Admins/Settings/DataStructures/Wizard/Wizard";
import CreateConfigValue from "@/components/Projects/Settings/Config/Create";
import { bus } from "@/main";
import {
  copyToClipboard,
  generateHash,
  isJson
} from "@/components/Tools/helperFunctions";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-php.min";
import "prismjs/components/prism-graphql.min";
import "prismjs/components/prism-twig.min";
import "prismjs/themes/prism-tomorrow.css";
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
import Tooltip from "@/components/Tools/Tooltip";
import { checkTime } from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";
import TokenEdit from "@/components/Projects/Settings/WorkflowToken/Edit";

export default {
  components: {
    CreateDataStructure,
    CreateConfigValue,
    Editor,
    FieldHelper: () => import("./FieldHelper"),
    PrismEditor,
    Tooltip,
    TokenEdit
  },
  props: {
    field: {
      type: Object
    },
    configValues: {
      type: Array
    },
    outputValues: {
      type: Array,
      default: () => []
    },
    debugValues: {
      type: Array,
      default: () => []
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    node: {
      type: Object,
      default: () => {}
    },
    conditionValue: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      configValue: null,
      valueIndex: null,
      debugValue: null,
      showPassword: false,
      showConfigValues: false,
      x: 0,
      y: 0,
      width: 100,
      selectedConfigValue: undefined,
      editorOptions: {
        theme: "snow"
      },
      newCondition: "",
      operators: [
        { operator: "==", name: "equals" },
        { operator: "===", name: "strictEquals" },
        { operator: "!=", name: "notEquals" },
        { operator: "!==", name: "strictNotEquals" },
        { operator: ">", name: "greaterThan" },
        { operator: ">", name: "smallerThan" },
        { operator: ">=", name: "greaterEquals" },
        { operator: "<=", name: "smallerEquals" },
        { operator: "IS NULL", name: "isNull" },
        { operator: "IS NOT NULL", name: "isNotNull" },
        { operator: "IS EMPTY", name: "isEmpty" },
        { operator: "IS NOT EMPTY", name: "isNotEmpty" }
      ],
      disableSecondField: ["isNull", "isNotNull", "isEmpty", "isNotEmpty"],
      connectionOperators: [
        { text: "AND", value: "and" },
        { text: "OR", value: "or" }
      ],
      showConditions: true,
      tabSize: 4,
      createDataStructureState: 0,
      dataStructureConfiguration: {
        dataStructureService: new DataStructures()
      },
      jsonData: "",
      cancelAction: false,
      elementSelectorNodeFound: null,
      elementSelectorStrokeOriginalColor: "#9babbd",
      elementSelectorStrokeColorInterval: null,
      elementSelectorActive: false,
      componentKey: 0
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    inputType: function () {
      if (
        this.field.type === "int" &&
        this.value.toString().includes("{{", "}}")
      ) {
        return "text";
      }
      if (this.field.type === "string") {
        return "text";
      }
      if (this.showConfigValues) {
        return "text";
      }
      if (this.field.type === "typeCast") {
        return "text";
      }
      return "number";
    },
    validationState: function () {
      if (
        this.conditionValue &&
        !this.isDisabled &&
        !this.value &&
        this.areaInvalid
      ) {
        return "is-invalid";
      }

      if (!this.field.required || (!this.edited && !this.areaInvalid)) {
        return "";
      }
      if (
        !this.value ||
        (this.field.type === "time" && !checkTime(this.value))
      ) {
        return "is-invalid";
      }
      return "is-valid";
    },
    configValuesFlat: function () {
      let configValues = [];
      this.configValues.forEach(configValue => {
        if (configValue.type === "json") {
          Object.entries(configValue.value).forEach(value => {
            configValues.push({
              label: `${configValue.label}.${value[0]}`,
              name: `${configValue.name}.${value[0]}`,
              value: value[1],
              type: "text"
            });
          });
        } else {
          configValues.push(configValue);
        }
      });
      return configValues;
    },
    configValuesFiltered: function () {
      if (
        this.field.sDisabled !== undefined &&
        this.field.configValuesDisabled
      ) {
        return [];
      }

      let values =
        this.configValuesFlat.filter(item => this.checkType(item)) ?? [];
      if (!this.value || typeof this.value !== "string") return values;
      return values.filter(item => {
        return (
          JSON.stringify(item)
            .toLowerCase()
            .includes(this.value.toString().toLowerCase()) ||
          this.configValueSyntax(item).includes(this.value)
        );
      });
    },
    outputValuesFiltered: function () {
      if (
        this.field.outputValuesDisabled !== undefined &&
        this.field.outputValuesDisabled
      ) {
        return [];
      }
      if (!this.value || typeof this.value !== "string") {
        return this.outputValues;
      }
      return this.outputValues.filter(item => {
        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          this.outputValueSyntax(item).includes(this.value)
        );
      });
    },
    debugValuesFiltered: function () {
      if (!this.value || typeof this.value !== "string") {
        return this.debugValues;
      }

      return this.debugValues.filter(item => {
        if (typeof item.value !== "string") return true;

        return (
          item.value.toLowerCase().includes(this.value.toLowerCase()) ||
          item.syntax.includes(this.value)
        );
      });
    },
    debugValuesAllowed: function () {
      return (
        Object.keys(this.field).includes("debugValues") &&
        this.field.debugValues
      );
    },
    type: function () {
      if (this.field.type === "int") {
        return "number";
      } else if (
        this.field.type === "string" ||
        this.field.type === "textarea" ||
        this.field.type === "password"
      ) {
        return "text";
      }
      return "";
    },
    selectOptions: function () {
      if (!this.field.options) {
        return [];
      }

      let options = this.field.options.map(o => {
        if (typeof o === "string") {
          o = { value: o, label: o, group: null };
        }
        let label = o.value;
        if (o.label) {
          label = o.label;
        } else if (this.$te(`${this.field.option}Options.${o.value}`)) {
          label = this.$t(`${this.field.option}Options.${o.value}`);
        }

        return {
          value: o.value,
          label: label,
          group: o.group ?? null
        };
      });

      let optionsSorted = options;
      if (this.field.sort === "desc" || this.field.sort === -1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return b.group.localeCompare(a.group);
          }
          return b.label.localeCompare(a.label);
        });
      } else if (this.field.sort === "asc" || this.field.sort === 1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return a.group.localeCompare(b.group);
          }
          return a.label.localeCompare(b.label);
        });
      }

      let newOptions = [];
      let lastGroup = null;
      optionsSorted.forEach(option => {
        if (option.group !== null && lastGroup !== option.group) {
          newOptions.push({
            header: option.group
          });
          lastGroup = option.group;
        }
        newOptions.push(option);
      });
      return newOptions;
    },
    prismEditorClass: function () {
      const style = this.field.style ?? "dark";
      let c = "code-editor";
      if (style !== "light") {
        c = "editor " + c;
      }
      return c;
    },
    selectNodeElementIcon() {
      if (!this.elementSelectorNodeFound) {
        return null;
      }
      const workflowElementId =
        this.elementSelectorNodeFound.attrs.data.workflow_element_id;
      const originalElement = this.$root.$refs.WFDEditor.library.find(
        x => x.id === workflowElementId
      );
      const icon =
        "data:" +
        originalElement.icon.type +
        ";base64," +
        originalElement.icon.assetData.base64;
      return icon;
    },
    getTypeCastValueType() {
      if (typeof this.value !== "boolean" && Number(this.value)) {
        return "number";
      } else if (
        this.value === "true" ||
        this.value === "false" ||
        typeof this.value === "boolean"
      ) {
        return "bool";
      }
      return "string";
    }
  },
  watch: {
    value: function () {
      this.edited = true;
      let newValue = this.value;

      if (typeof newValue !== "boolean" && Number(newValue)) {
        newValue = parseFloat(newValue);
      } else if (newValue === "true" || newValue === "false") {
        newValue = Boolean(newValue);
      }

      if (this.configValue) {
        newValue = this.configValueSyntax(this.configValue);
      }

      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);

      if (this.field.name === "error_handling") {
        bus.$emit("show-hide-critical-path");
      } else if (this.field.name === "functions") {
        if (this.field.options === undefined || this.field.options === null) {
          return;
        }
        const option = this.field.options.find(o => o.value === this.value);
        if (option && option.types) {
          this.node.attrs.data.input[0].value = JSON.stringify(
            option.types,
            null,
            1
          );
        }
      }

      if (this.field.type === "time") {
        this.checkRequiredFields();
      }
    }
  },
  mounted() {
    if (this.field.type === "json") {
      if (
        this.field.singleEntry &&
        (this.field.value === undefined || this.field.value.length === 0)
      ) {
        this.value = [];
        this.addValue();
      } else if (this.field.singleEntry) {
        this.value =
          this.field.value.length === undefined
            ? [this.field.value]
            : this.field.value;
      } else {
        this.value = this.field.value ?? [];
      }
      return;
    } else if (this.field.type === "checkbox") {
      this.value = this.field.value ?? false;
      return;
    }
    this.value = this.field.value ?? "";

    if (typeof this.value === "string" && this.value.startsWith("{{config")) {
      const configValue = this.configValuesFlat.find(
        cf => cf.name === this.value.replace("{{config.", "").replace("}}", "")
      );

      if (configValue) {
        this.setConfigValue(configValue);
      }
    } else if (
      typeof this.value === "string" &&
      this.value.startsWith("{{output")
    ) {
      const outputValue = this.outputValues.find(
        dv => dv.value === this.value.replace("{{output.", "").replace("}}", "")
      );
      if (outputValue) {
        this.setOutputValue(outputValue);
      }
    }

    if (this.field.type === "elementSelector") {
      this.elementSelectorNodeFound = this.$root.$refs.WFDEditor.nodes.find(
        x => x.attrs.data.hash === this.field.value
      );
      bus.$on("nodeSelected", this.nodeSelected);
    }

    bus.$on("fileAccessTreeSelected", this.onFileAccessTreeSelected);
    bus.$on("fireActionFinished", this.onFireActionFinished);
  },
  beforeDestroy() {
    this.blinkElementSelectorNodeStop();
  },
  destroyed() {
    if (this.field.type === "elementSelector") {
      bus.$off("nodeSelected", this.nodeSelected);
    }

    bus.$off("fileAccessTreeSelected", this.onFileAccessTreeSelected);
    bus.$off("fireActionFinished", this.onFireActionFinished);
  },
  methods: {
    onFileAccessTreeSelected(selected) {
      if (selected.isFile && this.field.name === "searchPattern") {
        this.value = "* | *name* | *.txt |";
      }
      if (this.field.type === "tenantStorage") {
        this.value = selected.filePath;
      }
      if (this.field.type === "hidden") {
        this.value = selected.rootPath;
      }
      if (this.field.name === "isFile") {
        this.value = selected.isFile;
      }
    },
    onFireActionFinished() {
      this.cancelAction = false;
    },
    checkType(item) {
      if (this.type === "number") {
        return !isNaN(item.value);
      }

      return true;
      //this.type === "text" || this.field.type === "time";
    },
    onEditorChange(value) {
      this.field.value = value.html;
    },
    showMenu(state = true, i = null, key = null) {
      if (i !== null && key !== null) {
        this.valueIndex = {
          i: i,
          key: key
        };
      }

      if (!state && this.field.type === "int" && isNaN(this.value)) {
        this.value = "";
      }
      let pos = this.$refs.wrapper?.getBoundingClientRect();
      this.x = pos.x;
      this.y = pos.y + pos.height;
      this.width = pos.width;
      this.showConfigValues = state;
      this.selectedConfigValue = undefined;
    },
    configValueSyntax(configValue) {
      return `{{config.${configValue.name}}}`;
    },
    setConfigValue(configValue) {
      this.configValue = configValue;
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][this.valueIndex.key] =
          this.configValueSyntax(configValue);
        return;
      }
      this.value =
        typeof configValue.value === "object"
          ? JSON.stringify(configValue.value)
          : configValue.value;
      this.debugValue = null;
    },
    outputValueSyntax(outputValue) {
      return `{{output.${outputValue.value}}}`;
    },
    setOutputValue(outputValue) {
      this.configValue = null;
      this.debugValue = null;
      this.$set(this.field, "jsonData", outputValue.data);
      if (this.field.type === "json") {
        this.value[this.valueIndex.i][this.valueIndex.key] =
          this.outputValueSyntax(outputValue);
        return;
      }
      this.value = this.outputValueSyntax(outputValue);
    },
    setDebugValue(debugValue) {
      this.debugValue = debugValue;
      this.$set(this.field, "jsonData", JSON.stringify(debugValue.value));
      if (typeof debugValue.value !== "string") {
        this.value = debugValue.syntax;
        return;
      }

      this.value = debugValue.value;
    },
    selectChange(value) {
      this.value = value;
      if (this.field.onChange !== undefined) {
        bus.$emit(
          "fireAction",
          {
            name: this.field.onChange,
            label: this.field.onChange
          },
          false
        );
      }

      setTimeout(this.checkRequiredFields, 100);
    },
    addValue() {
      let element = {};
      this.field.fields.forEach(field => {
        this.$set(element, field.name, "");
      });
      this.value.push(element);
    },
    removeValue(index) {
      this.value.splice(index, 1);
    },
    deleteValue() {
      this.resetConfigDebugValue();
      this.value = "";
      this.field.value = "";
      this.field.jsonData = null;
    },
    resetConfigDebugValue() {
      this.configValue = null;
      this.debugValue = null;
    },
    addConfigValue() {
      this.$refs["addConfigValueModal"].show();
    },
    configValueSaved(configValue) {
      this.setConfigValue(configValue);
      this.$refs["addConfigValueModal"].hide();
      bus.$emit("update-config-values");
    },
    showCodeFullscreen() {
      this.$refs["codeFullScreen"].show();
    },
    checkRequiredFields() {
      bus.$emit("checkRequiredFields");
    },
    fireAction(field) {
      this.cancelAction = !this.cancelAction;
      bus.$emit("fireAction", field, !this.cancelAction);
    },
    openFileManager() {
      bus.$emit("openWFDFileManager", { nodeId: this.node.attrs.id });
    },
    addConditionValue(condition) {
      condition.values.push({
        type: "condition",
        connection_operator: "and",
        left: "",
        operator: this.operators[0].name,
        right: ""
      });
    },
    removeConditionValue(condition, value) {
      if (
        value.type === "condition" &&
        condition.values.filter(v => v.type === "condition").length <= 1
      )
        return;
      let index = condition.values.indexOf(value);
      if (index < 0) return;
      condition.values.splice(index, 1);
    },
    addBracket(condition, type = "open") {
      condition.values.push({
        type: type === "open" ? "bracketOpen" : "bracketClose",
        connection_operator: "and"
      });
    },
    addCondition() {
      let args = {
        node: this.node,
        id: generateHash()
      };
      bus.$emit("add-condition", args);
      this.reRenderList();
    },
    removeCondition(condition) {
      let configuration = this.node.attrs.data.configuration;
      let conditionsConfig = configuration.find(el => el.name === "conditions");
      let conditionsKey = configuration.indexOf(conditionsConfig);
      if (configuration[conditionsKey].value.length <= 2) return;
      let index = configuration[conditionsKey].value.indexOf(condition);
      if (index < 0) return;
      let args = {
        node: this.node,
        condition: condition
      };
      bus.$emit("remove-condition", args);
      this.reRenderList();
    },
    showConnectionOperator(condition, value, index) {
      return (
        condition.values[index - 1] &&
        condition.values[index - 1]?.type !== "bracketOpen" &&
        value.type !== "bracketClose"
      );
    },
    reRenderList() {
      this.showConditions = false;
      this.showConditions = true;
    },
    conditionToString(condition) {
      let string = "";
      condition.values.forEach((c, index) => {
        string += " ";
        if (this.showConnectionOperator(condition, c, index)) {
          let connectionOperator = this.connectionOperators.find(
            co => co.value === c.connection_operator
          ).text;
          string += connectionOperator + " ";
        }
        if (c.type === "condition") {
          let operator = this.operators.find(o => o.name === c.operator);
          string += `${c.left ?? ""} ${operator?.operator ?? ""} ${
            c.right ?? ""
          }`;
        } else if (c.type === "bracketOpen") {
          string += "(";
        } else if (c.type === "bracketClose") {
          string += ")";
        }
      });
      return string;
    },
    operatorSelected(value, operator) {
      if (this.disableSecondField.includes(operator)) {
        value.right = "";
      }
    },
    setValue(object, key, value) {
      this.$set(object, key, value);
      this.reRenderList();
    },
    prismEditorHighlighter(code) {
      const language = this.field.lang ?? "php";
      const grammar = languages[language];

      return highlight(code, grammar, language);
    },
    addDataStructure() {
      if (this.field.jsonData === undefined) {
        this.$toast.fire({
          title: this.$t("workflowDesigner.createDataStructureJsonError", {
            name: this.field.value
          }),
          icon: "warning"
        });
        return;
      }

      this.jsonData = this.field.jsonData;

      if (!isJson(this.jsonData)) {
        this.$toast.fire({
          title: this.$t("workflowDesigner.createDataStructureJsonError", {
            name: this.field.value
          }),
          icon: "warning"
        });
        return;
      }

      this.createDataStructureState = 0;
      this.$refs["addDataStructureModal"].show();
    },
    showDataStructureEditor() {
      this.createDataStructureState = 1;
    },
    onDataStructureSaved(dataStructure) {
      this.$refs["addDataStructureModal"].hide();
      this.deleteValue();

      const dataStructureSelectField = this.node.attrs.data.configuration.find(
        d => d.name === "dataStructureSelect"
      );
      if (dataStructureSelectField) {
        this.$set(dataStructureSelectField, "value", "existing");
      }

      const dataStructureField = this.node.attrs.data.configuration.find(
        d => d.name === "dataStructure"
      );
      if (!dataStructureField) return;

      dataStructureField.options.push({
        value: dataStructure.id,
        label: dataStructure.classname
      });
      this.$set(dataStructureField, "value", dataStructure.id);
    },
    onDataStructureCanceled() {
      this.$refs["addDataStructureModal"].hide();
    },
    blinkElementSelectorNode() {
      if (this.elementSelectorStrokeColorInterval) {
        return;
      }
      this.elementSelectorStrokeColorInterval = setInterval(() => {
        let newColor =
          this.elementSelectorNodeFound.attrs.stroke ===
          this.elementSelectorStrokeOriginalColor
            ? "#ff0000"
            : this.elementSelectorStrokeOriginalColor;
        this.elementSelectorNodeFound.stroke(newColor);
        this.$root.$refs.WFDEditor.stage.batchDraw();
      }, 150);
      setTimeout(() => {
        this.blinkElementSelectorNodeStop();
      }, 3000);
    },
    blinkElementSelectorNodeStop() {
      if (!this.elementSelectorNodeFound) {
        return;
      }
      clearInterval(this.elementSelectorStrokeColorInterval);
      this.elementSelectorStrokeColorInterval = null;
      this.elementSelectorNodeFound.stroke(
        this.elementSelectorStrokeOriginalColor
      );
      this.$root.$refs.WFDEditor.stage.batchDraw();
    },
    selectNodeElement() {
      this.elementSelectorActive = true;
      document.getElementById("konva").style.cursor = "crosshair";
      this.$root.$refs.WFDEditor.preventNodeClick = true;
    },
    selectNodeElementAbort() {
      this.elementSelectorActive = false;
      document.getElementById("konva").style.cursor = "pointer";
      this.$root.$refs.WFDEditor.preventNodeClick = false;
    },
    nodeSelected(node) {
      if (!this.elementSelectorActive) {
        return;
      }
      node.stroke(this.elementSelectorStrokeOriginalColor);
      this.elementSelectorActive = false;
      this.elementSelectorNodeFound = node;
      document.getElementById("konva").style.cursor = "pointer";
      this.value = this.elementSelectorNodeFound.attrs.id;
      this.field.value = this.elementSelectorNodeFound.attrs.id;
      this.$root.$refs.WFDEditor.preventNodeClick = false;
    },
    copyText(text) {
      copyToClipboard(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    showAddToken() {
      this.$refs["addTokenModal"].show();
    },
    tokenSaved(token) {
      this.$refs["addTokenModal"].hide();
      this.field.options.push({
        value: token.id,
        label: token.label
      });
      this.value = token.id;
    }
  }
};
</script>

<style>
.modal-body.add-config-value {
  padding: 0;
}
</style>

<style lang="scss">
.ql-editor {
  overflow-y: scroll;
  resize: vertical;
}
.quill-editor {
  .ql-toolbar,
  .ql-container {
    border-color: #e4e6ef;
  }
}

.input-group-append {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}

.v-input--selection-controls__input .v-icon {
  color: #e4ebef;
}

.code-editor > .prism-editor__textarea:focus {
  outline: none;
}
.code-editor > .prism-editor__container {
  min-height: 300px !important;
}
.editor.code-editor {
  max-height: 400px;
  .modal & {
    max-height: none;
  }
}
.code-editor {
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 13px;
  line-height: 1.5;
  padding: 5px;
  border: 1px solid #e4e6ef;
}

.modal-xl.create-data-structure {
  max-width: 90%;
}

#codeFullScreen {
  .modal-dialog {
    max-width: 95%;
  }

  .mapping-wrapper {
    max-height: calc(100vh - 140px);
  }

  &.modal-code {
    .modal-dialog {
      height: 95vh;

      .modal-content {
        height: 100%;

        .editor {
          height: 80vh;
          overflow-y: auto;

          .prism-editor__container {
            height: auto;
          }
        }
      }
    }
  }
}
</style>
